import { Banner, Button, ChoiceList, Divider, Icon, Popover, Select, Text, TextField } from "@shopify/polaris";
import { format } from "date-fns";
import _, { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { XIcon } from '@shopify/polaris-icons';

function SectionInstalled({ shop, data }) {
    const [sections, setSections] = useState(data);
    const [searchText, setSearchText] = useState('');
    const [popoverResourceActive, setPopoverResourceActive] = useState(false);
    const [resource, setResource] = useState(['section']);
    const [popoverPackageActive, setPopoverPackageActive] = useState(false);
    const [packageItem, setPackageItem] = useState([]);
    const [sortBy, setSortBy] = useState('name');
    const [filters, setFilters] = useState({
        _s: '',
        sortBy: 'name',
        resource: ['section'],
        package: []
    });

    const togglePopoverResourceActive = () => {
        setPopoverResourceActive((popoverActive) => !popoverActive)
    };
    const handleChangeResource = (value) => {
        setResource(value);
        setFilters(prev => ({ ...prev, resource: value }));
    };

    const togglePopoverPackageActive = () => {
        setPopoverPackageActive((popoverActive) => !popoverActive)
    };
    const handleChangePackage = (value) => {
        setPackageItem(value);
        setFilters(prev => ({ ...prev, package: value }));
    };

    const delayedSearch = useCallback(
        debounce((query) => {
            setFilters(prev => ({ ...prev, _s: query.toLowerCase() }));
        }, 250),
        []
    );

    const handleChangeSearchText = (value) => {
        setSearchText(value);
        delayedSearch(value);
    }

    const handleChangeSortBy = (value, _data = false) => {
        setSortBy(value);
        setFilters(prev => ({ ...prev, sortBy: value }));
    }

    useEffect(() => {
        let _sections = data.filter(item => {
            const itemName = item.name.toLowerCase();
            return itemName.includes(filters._s);
        });

        // if (filters.resource.length) {
        //     _sections = _sections.filter(item => filters.resource.includes(item.resource));
        // }

        if (filters.package.length) {
            _sections = _sections.filter(item => filters.package.includes(item.package));
        }

        if (filters.sortBy === 'date') {
            _sections = _sections.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else {
            _sections = _sections.sort((a, b) => {
                const key = 'name';
                return a[key].localeCompare(b[key]);
            });
        }

        setSections(_sections);

    }, [filters, data]);

    return (
        <>
            <div className="mb-100 mt-400 d-flex gap-300">
                <div style={{ width: '400px' }}>
                    <TextField
                        placeholder="Enter name..."
                        type="text"
                        value={searchText}
                        onChange={handleChangeSearchText}
                    />
                </div>
                <Popover
                    active={popoverPackageActive}
                    activator={(
                        <Button disclosure size="large" onClick={togglePopoverPackageActive}>Package</Button>
                    )}
                    onClose={togglePopoverPackageActive}
                    ariaHaspopup={false}
                    sectioned
                    preferredAlignment="left"
                >
                    <ChoiceList
                        allowMultiple
                        choices={[
                            {
                                label: 'Free',
                                value: 'free'
                            },
                            {
                                label: 'Basic',
                                value: 'basic'
                            },
                            {
                                label: 'Pro',
                                value: 'pro'
                            }                           
                        ]}
                        selected={packageItem}
                        onChange={handleChangePackage}
                    />
                </Popover>
                <Popover
                    active={popoverResourceActive}
                    activator={(
                        <Button disclosure size="large" onClick={togglePopoverResourceActive}>Resources</Button>
                    )}
                    onClose={togglePopoverResourceActive}
                    ariaHaspopup={false}
                    sectioned
                    preferredAlignment="left"
                >
                    <ChoiceList
                        allowMultiple
                        choices={[
                            {
                                label: 'Section',
                                value: 'section'
                            },
                            {
                                label: 'Template',
                                value: 'template'
                            },
                            {
                                label: 'Theme',
                                value: 'theme'
                            },
                            {
                                label: 'Page builder',
                                value: 'template-builder'
                            },
                            {
                                label: 'Theme builder',
                                value: 'theme-builder'
                            }
                        ]}
                        selected={resource}
                        onChange={handleChangeResource}
                    />
                </Popover>
                <Select
                    label="Sort by"
                    labelInline
                    value={sortBy}
                    onChange={handleChangeSortBy}
                    options={[
                        {
                            label: 'Name A-Z',
                            value: 'name'
                        },
                        {
                            label: 'Created date',
                            value: 'date'
                        }
                    ]}
                >

                </Select>
            </div>
            <div className="">
                {
                    resource.length ?
                        (
                            <div className="list-tags d-flex gap-200">
                                Resource
                                {
                                    resource.map(item => {
                                        return (
                                            <span key={`${item}-resource`} className="tag d-flex">{item} <span onClick={() => {
                                                const remainItems = resource.filter(it => it !== item);
                                                setResource(remainItems);
                                                setFilters(prev => ({ ...prev, resource: remainItems }))
                                            }}><Icon source={XIcon} tone="base"></Icon></span></span>
                                        )
                                    })
                                }
                            </div>
                        ) : null
                }
            </div>
            <div className="">
                {
                    packageItem.length ?
                        (
                            <div className="list-tags d-flex gap-200">
                                Package
                                {
                                    packageItem.map(item => {
                                        return (
                                            <span key={`${item}-package`} className="tag d-flex">{item} <span onClick={() => {
                                                const remainItems = packageItem.filter(it => it !== item);
                                                setPackageItem(remainItems);
                                                setFilters(prev => ({ ...prev, package: remainItems }))
                                            }}><Icon source={XIcon} tone="base"></Icon></span></span>
                                        )
                                    })
                                }
                            </div>
                        ) : null
                }
            </div>
            <div className="my-400">
                <Banner hideIcon><Text fontWeight="bold" variant="headingLg">{sections.length} {sections.length === 1 ? 'item' : 'items'}</Text></Banner>
            </div>
            <div className="d-flex align-items-center gap-300 pb-300" style={{ width: '100%' }}>
                <div style={{ width: '5%' }}><b>#</b></div>
                <div style={{ width: '30%' }}><b>Name</b></div>
                <div style={{ width: '15%' }}><b>Package</b></div>
                <div style={{ width: '15%' }}><b>Theme ID</b></div>
                <div style={{ width: '15%' }}><b>Resource</b></div>
                <div style={{ width: '20%' }}><b>Created at</b></div>
            </div>
            {
                sections.length ? sections.map((section, idx) => {
                    return (
                        <>
                            <Divider />
                            <div className="_list-item py-300 d-flex gap-300" key={`installed_sections_sections-${idx}`}>
                                <div style={{ width: '5%' }}><b>{(idx + 1)}</b></div>
                                <div style={{ width: '30%' }}>
                                    <Text variant="bodyMd" fontWeight="bold" as="span">{section.name}</Text>
                                </div>
                                <div style={{ width: '15%' }}>
                                    <Text variant="bodyMd" fontWeight="bold" as="span">{_.capitalize(section.package)}</Text>
                                </div>
                                <div style={{ width: '15%' }}>
                                    <a href={`https://${shop?.shopUrl}?preview_theme_id=${section.theme}`} target="_blank" className="text-link">
                                        <Text variant="bodyMd" fontWeight="regular" as="span">{section.theme}</Text>
                                    </a>
                                </div>
                                <div style={{ width: '15%' }}>
                                    <Text variant="bodyMd" fontWeight="regular" as="span">{section.resource}</Text>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <Text variant="bodyMd" fontWeight="regular" as="span">{format(section.createdAt, "dd-MM-yyyy HH:mm:ss", { timeZone: 'Asia/Bangkok' })}</Text>
                                </div>
                            </div>                            
                        </>
                    )
                }) : <div className="py-300"><Banner>No data</Banner></div>
            }
        </>
    )
}

export default SectionInstalled;