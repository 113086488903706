import { TextField as TextFieldPolaris } from "@shopify/polaris";
import { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function RichtextField({ data,onChange,className  }) {
    const [value, setValue] = useState("");


    const modules = {
        toolbar: [
           [{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'bold', 'italic', 'link', { 'list': 'bullet' }, { 'list': 'ordered'}]
        ],
        clipboard: {
            matchVisual: false
        }
    }

    const formats = [
        'header', 'bold', 'italic', 'link', 'list', 'list'
    ]
    useEffect(() => {
        setValue(data);
    }, [data]);

    const handleChange = (value) => {
        setValue(value);
        onChange(value);
    }

    return (
        <>
            <div className={`control`}>
                <ReactQuill
                    className={className || ""}
                    theme="snow"
                    value={value}
                    onChange={handleChange}
                    modules={modules}
                    formats={formats}
                    preserveWhitespace
                />
            </div>
        </>
    )
}

export default RichtextField;