import { Badge, Button, ButtonGroup, Card, Divider, Icon, Spinner, Text, TextField } from "@shopify/polaris"
import { useCallback, useEffect, useRef, useState } from "react"
import { capitalize, debounce } from "lodash";
import { ENDPOINT, convertToAsiaTimeZone } from "../../utils/functions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DuplicateIcon } from '@shopify/polaris-icons';
import { format } from "date-fns";
import SectionInstalled from "./items/Section";
import TemplateInstalled from "./items/Template";
import ThemeInstalled from "./items/Theme";
import StyleInstalled from "./items/Style";
import PageBuilderInstalled from "./items/PageBuilder";
import ThemeBuilderInstalled from "./items/ThemeBuilder";
import CustomCss from "./actions/CustomCss";
import CustomJs from "./actions/CustomJs";
import CustomLiquid from "./actions/CustomLiquid";

function Search({ _s, username }) {
    const [merchants, setMerchants] = useState([])
    const [action, setAction] = useState(null)
    const [childAction, setChildAction] = useState(null)
    const [readLog, setReadLog] = useState([])
    const [cssCode, setCssCode] = useState('Loading data...')
    const [jsCode, setJsCode] = useState('Loading data...')
    const [liquidCode, setLiquidCode] = useState({
        fileName: '',
        fileCode: ''
    })
    const [cssState, setCssStatus] = useState('')
    const [jsState, setJsStatus] = useState('')
    const [liquidState, setLiquidStatus] = useState('')
    const [loading, setLoading] = useState({ css: false, js: false, liquid: false })
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        if (_s !== '') {
            handleGetMerchant(_s)
        }
    }, [_s])

    const handleGetMerchant = async (query) => {
        setPageLoading(true);
        try {
            const response = await fetch(`${ENDPOINT}/api/get-merchant`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    _s: query
                }),
            });
            const result = await response.json();
            if (result.status) {
                setMerchants(result)
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setPageLoading(false);
    }

    const handleGetLog = async (prev, index, shopUrl) => {
        setAction(({ ...prev, [index]: 'logs' }));

        try {
            const response = await fetch(`${ENDPOINT}/api/get-logs`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    shopUrl
                }),
            });
            const result = await response.json();
            setReadLog(result.data.lines);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const textToCopyRef = useRef(null);
    const copyText = () => {
        const textToCopy = textToCopyRef.current.innerText;
        const textarea = document.createElement('textarea');
        textarea.value = "Summarize the user actions from the following log: \n" + textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('Text copied to clipboard!');
    }

    const handleCustomCss = async (prev, index, shopUrl) => {
        setAction(({ ...prev, [index]: 'css' }));
    }

    const handleCustomJs = async (prev, index, shopUrl) => {
        setAction(({ ...prev, [index]: 'js' }));
    }

    const handleCustomLiquid = async (prev, index, shopUrl) => {
        setAction(({ ...prev, [index]: 'liquid' }));
    }

    const handleSaveLiquid = async (shopUrl) => {
        setLoading(prev => ({ ...prev, liquid: true }))
        const response = await fetch(`${ENDPOINT}/api/save-liquid`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl,
                liquidCode
            }),
        });
        const result = await response.json();
        setLiquidStatus(result?.message)
        setLoading(prev => ({ ...prev, liquid: false }))

        setTimeout(() => {
            setLiquidStatus('')
        }, 5000)
    }

    return (
        <>
            {!!pageLoading ? (
                <div className="d-flex justify-center p-600" style={{minHeight: '500px'}}>
                    <Spinner size="large" />
                </div>
            ) : null}
            {
                merchants?.data && merchants.data.map((_item, index) => {
                    const _action = action?.[index] ?? 'installed_sections'
                    const _childAction = childAction?.[index] ?? 'installed_sections_sections'
                    let item = _item
                    let itemTemp = _item
                    if (!!item.meta.isUninstalled && item?.oldData) {
                        item = { ...item, ...item?.oldData }
                    }

                    let sectionData = item?.myLibrary?.sections || [];
                    sectionData = Array.from(
                        new Map(
                            sectionData.map(item => [
                                `${item.name}-${item.theme}-${item.resource}`,
                                item
                            ])
                        ).values()
                    );

                    const pageBuilderData = merchants?.pageBuilder ?? [];
                    const themeBuilderData = merchants?.themeBuilder ?? [];
                    const mySections = merchants?.sections ?? [];
                    const myTemplates = merchants?.templates ?? [];
                    const myThemes = merchants?.themes ?? [];
                    const myStyles = merchants?.styles ?? [];
                    
                    return (
                        <div className="_item" key={item?._id}>
                            <div className="d-flex">
                                <div className="_colleft">
                                    <Text tone="success" as="h3" variant="headingMd">
                                        <div className="d-flex gap-100">
                                            <span>INFO</span>
                                            {
                                                !!itemTemp.meta.isUninstalled ? (
                                                    <Badge tone="critical" hideIcon>
                                                        <Text alignment="justify" as="p" fontWeight="bold">UNINSTALLED</Text>
                                                    </Badge>
                                                ) : (
                                                    <Badge tone="success" hideIcon>
                                                        <Text alignment="justify" as="p" fontWeight="bold">ACTIVE</Text>
                                                    </Badge>
                                                )
                                            }
                                        </div>
                                    </Text>
                                    <p className="mt-300">
                                        Shop name<br />
                                        <b>{item?.shopName}</b>
                                    </p>
                                    <p className="mt-200">
                                        Shop URL<br />
                                        <b><a href={`https://${item?.shopUrl}`} target="_blank">{item?.shopUrl}</a></b>
                                    </p>
                                    <p className="mt-200">
                                        Shopify Plan<br />
                                        <b>{item?.meta.shopifyPlan}</b>
                                    </p>
                                    <p className="mt-200">
                                        Email<br />
                                        <b>{item?.email}</b>
                                    </p>
                                    <p className="mt-200">
                                        App Plan<br />
                                        <b>{item?.plan.name}</b>
                                    </p>
                                    {
                                        !!itemTemp.meta.isUninstalled ? (
                                            <>
                                                <p className="mt-200">
                                                    Installed at<br />
                                                    <b>{convertToAsiaTimeZone(item.createdAt)}</b>
                                                </p>
                                                <p className="mt-200">
                                                    Uninstalled at<br />
                                                    <b>{convertToAsiaTimeZone(item.updatedAt)}</b>
                                                </p>
                                            </>
                                        ) : (
                                            <p className="mt-200">
                                                Installed at<br />
                                                <b>{convertToAsiaTimeZone(item.createdAt)}</b>
                                            </p>
                                        )
                                    }
                                    {
                                        itemTemp.meta.isDeletedData ? (
                                            <p className="mt-400">
                                                <Badge tone="critical" hideIcon>
                                                    <Text alignment="justify" as="p" fontWeight="bold">IS DELETED APP DATA</Text>
                                                </Badge>
                                            </p>
                                        ) : null
                                    }
                                </div>
                                <div className="_colright">
                                    <div className="_tab">
                                        <div className="d-flex justify-space-between">
                                            <ButtonGroup variant="segmented">
                                                <Button pressed={_action === 'installed_sections'} onClick={() => {
                                                    setAction(prev => ({ ...prev, [index]: 'installed_sections' }))
                                                    setChildAction(prev => ({ ...prev, [index]: 'installed_sections_sections' }))
                                                }}>
                                                    <span className="pr-100">Installed items</span>
                                                </Button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {
                                                    username === 'supporter' || username === 'super' ? (
                                                        <div className="d-flex gap-100">
                                                            <Button variant="monochromePlain">Customize code</Button>
                                                            <Button variant={_action === 'css' ? 'primary' : 'secondary'} onClick={(prev) => handleCustomCss(prev, index, item?.shopUrl)}>Css</Button>
                                                            <Button variant={_action === 'js' ? 'primary' : 'secondary'} onClick={(prev) => handleCustomJs(prev, index, item?.shopUrl)}>Js</Button>
                                                            <Button variant={_action === 'liquid' ? 'primary' : 'secondary'} onClick={(prev) => handleCustomLiquid(prev, index, item?.shopUrl)}>Liquid</Button>
                                                        </div>
                                                    ) : null
                                                }

                                                <Button variant={_action === 'logs' ? 'primary' : 'secondary'} onClick={(prev) => handleGetLog(prev, index, item?.shopUrl)}>Logs</Button>
                                                {
                                                    _action === 'logs' ? (
                                                        <Button primary onClick={copyText}
                                                            prefix={
                                                                <Icon
                                                                    source={DuplicateIcon}
                                                                    tone="base"
                                                                />
                                                            }
                                                        >
                                                            Copy
                                                        </Button>
                                                    ) : null
                                                }
                                            </ButtonGroup>

                                        </div>
                                        <div className="_tab-content">
                                            <Card>
                                                {
                                                    _action === 'css' ? (
                                                        <CustomCss item={item} />
                                                    ) : null
                                                }

                                                {
                                                    _action === 'js' ? (
                                                        <CustomJs item={item} />
                                                    ) : null
                                                }

                                                {
                                                    _action === 'liquid' ? (
                                                        <CustomLiquid item={item} />
                                                    ) : null
                                                }

                                                {
                                                    _action === 'liquid1' ? (
                                                        <>
                                                            <div className="css-records mb-400">
                                                                <div className="mb-300">
                                                                    <TextField
                                                                        label="File name"
                                                                        placeholder="sections/smi-featured-collection-1-1.liquid"
                                                                        value={liquidCode.fileName}
                                                                        onChange={value => setLiquidCode(prev => ({ ...prev, fileName: value }))}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>
                                                                <TextField
                                                                    label="File code"
                                                                    placeholder="{%- assign demo = settings.demo_variable -%}"
                                                                    value={liquidCode.fileCode}
                                                                    onChange={value => setLiquidCode(prev => ({ ...prev, fileCode: value }))}
                                                                    multiline={12}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div className="d-flex align-items-center gap-300">
                                                                <Button variant="primary" loading={loading?.liquid} onClick={() => handleSaveLiquid(item?.shopUrl)}>Save</Button>
                                                                {liquidState ? <Text>{liquidState}</Text> : ''}
                                                            </div>
                                                        </>
                                                    ) : null
                                                }

                                                {
                                                    _action === 'logs' ? (
                                                        <div className="logs-records" ref={textToCopyRef}>
                                                            {
                                                                readLog.length ? readLog.map((log, idx) => {
                                                                    return (
                                                                        <div className="_list-item" key={`logs-${idx}`}>
                                                                            {log}<br />
                                                                        </div>
                                                                    )
                                                                }) : 'No data!'
                                                            }

                                                        </div>
                                                    ) : null
                                                }

                                                {
                                                    _action === 'installed_sections' ? (
                                                        <div className="_tab-content-installed_sections">
                                                            <ButtonGroup variant="segmented">
                                                                <Button pressed={_childAction === 'installed_sections_sections'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'installed_sections_sections' }))}>
                                                                    <span className="mr-200">Sections</span>
                                                                    <Badge tone="info">{mySections.length}</Badge>
                                                                </Button>
                                                                <Button pressed={_childAction === 'installed_sections_templates'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'installed_sections_templates' }))}>
                                                                    <span className="mr-200">Templates</span>
                                                                    <Badge tone="info">{myTemplates.length}</Badge>
                                                                </Button>
                                                                <Button pressed={_childAction === 'installed_sections_themes'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'installed_sections_themes' }))}>
                                                                    <span className="mr-200">Themes</span>
                                                                    <Badge tone="info">{myThemes.length}</Badge>
                                                                </Button>
                                                                <Button pressed={_childAction === 'installed_sections_global_styles'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'installed_sections_global_styles' }))}>
                                                                    <span className="mr-200">Styles</span>
                                                                    <Badge tone="info">{myStyles.length}</Badge>
                                                                </Button>
                                                                <Button pressed={_childAction === 'installed_sections_pageBuilder'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'installed_sections_pageBuilder' }))}>
                                                                    <span className="mr-200">Page builder</span>
                                                                    <Badge tone="info">{pageBuilderData.length}</Badge>
                                                                </Button>
                                                                <Button pressed={_childAction === 'installed_sections_themeBuilder'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'installed_sections_themeBuilder' }))}>
                                                                    <span className="mr-200">Theme builder</span>
                                                                    <Badge tone="info">{themeBuilderData.length}</Badge>
                                                                </Button>
                                                            </ButtonGroup>
                                                            <div className="mb-300"></div>
                                                            {
                                                                _childAction === 'installed_sections_sections' ? (
                                                                    <SectionInstalled shop={item} data={mySections} />
                                                                ) : null
                                                            }

                                                            {
                                                                _childAction === 'installed_sections_templates' ? (
                                                                    <TemplateInstalled shop={item} data={myTemplates} />
                                                                ) : null
                                                            }

                                                            {
                                                                _childAction === 'installed_sections_themes' ? (
                                                                    <ThemeInstalled shop={item} data={myThemes} />
                                                                ) : null
                                                            }

                                                            {
                                                                _childAction === 'installed_sections_global_styles' ? (
                                                                    <StyleInstalled shop={item} data={myStyles} />
                                                                ) : null
                                                            }

                                                            {
                                                                _childAction === 'installed_sections_pageBuilder' ? (
                                                                    <PageBuilderInstalled shop={item} data={pageBuilderData} />
                                                                ) : null
                                                            }

                                                            {
                                                                _childAction === 'installed_sections_themeBuilder' ? (
                                                                    <ThemeBuilderInstalled shop={item} data={themeBuilderData} />
                                                                ) : null
                                                            }
                                                        </div>
                                                    ) : null
                                                }
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </>
    )
}

export default Search