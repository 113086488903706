import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash"
import { Badge, Button, Card, Frame, Page, Spinner, Text, TextField } from "@shopify/polaris"
import Pagination from "../common/Pagination";
import { ENDPOINT, convertToAsiaTimeZone } from "../../utils/functions";
import SyncSections from "./SyncSections";

function UnlockSections(props) {
    //State
    const [merchants, setMerchants] = useState([])
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({ total: 0 })
    const limit = 10
    const [searchText, setSearchText] = useState('')
    const [filters, setFilters] = useState({ _s: '', sort: 'recent', status: 'active' })
    const [loadingMerchant, setLoadingMerchant] = useState(false);
    const [loadingUnlock, setLoadingUnlock] = useState({});
    const [unlockData, setUnlockData] = useState({});

    const delayedSearch = useCallback(
        debounce((query) => {
            setFilters(prev => ({ ...prev, _s: query, page: 1 }))
        }, 250),
        []
    );

    useEffect(() => {
        const getMerchants = async () => {
            try {
                setLoadingMerchant(true)
                const response = await fetch(`${ENDPOINT}/api/get-merchants`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(filters),
                });
                const result = await response.json();
                if (result.status) {
                    setMerchants(result.nodes)
                    setPagination(result.pagination)
                    result.nodes.map(item => {
                        setUnlockData(prev => ({ ...prev, [item.shopUrl]: item?.meta?.sectionUnlocked }));
                    })
                }
            } catch (error) {
                console.error("Error:", error);
            }
            setLoadingMerchant(false)
        }

        getMerchants()
    }, [filters])

    useEffect(() => {
        setFilters(prev => ({ ...prev, page: page }))
    }, [page])

    const handleChangeSearchText = (value) => {
        setSearchText(value);
        delayedSearch(value);
    }

    const handleUnlock = async (section, lock) => {
        setLoadingUnlock({ [section.shopUrl]: true });
        const response = await fetch(`${ENDPOINT}/api/unlock`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl: section.shopUrl,
                lock
            }),
        });
        const result = await response.json();
        if (result.status) {
            setUnlockData(prev => ({ ...prev, [section.shopUrl]: lock }));
        } else {
            alert('Have an error, please try again!');
        }

        setLoadingUnlock({ [section.shopUrl]: false });
    }

    return (
        <Page title="Unlock sections" >
            <Card>
                <Frame>

                    <div className="all-merchants ">
                        <div className="d-flex gap-200 ">
                            <div className="flex-1">
                                <TextField
                                    placeholder="Enter your store info: Name, url, email"
                                    type="text"
                                    value={searchText}
                                    onChange={handleChangeSearchText}
                                />

                            </div>
                        </div>

                        {
                            !merchants.length ? (
                                <div className="mt-300 d-flex gap-100 p-relative align-items-center">
                                    <Text as="h3" variant="headingMd">No merchants</Text>
                                    {!!loadingMerchant && <div className="loading"><Spinner /></div>}
                                </div>
                            ) : (
                                <>
                                    <div className="mt-300 mb-300 gap-100 d-flex p-relative align-items-center">
                                        <Text as="h3" variant="headingMd">{pagination.total} merchants</Text>
                                        {!!loadingMerchant && <div className="loading"><Spinner /></div>}
                                    </div>
                                    <table className="mt-300">
                                        <thead>
                                            <tr>
                                                <th>Information</th>
                                                <th>Unlock free sections</th>
                                                <th width="40%">Gift sections</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                merchants.map((_item, idx) => {
                                                    let item = _item
                                                    let itemTemp = _item
                                                    if (!!item.meta.isUninstalled && item?.oldData) {
                                                        item = { ...item, ...item?.oldData }
                                                    }

                                                    let sectionData = item?.myLibrary?.sections || [];
                                                    sectionData = Array.from(
                                                        new Map(
                                                            sectionData.map(item => [
                                                                `${item.name}-${item.theme}-${item.resource}`,
                                                                item
                                                            ])
                                                        ).values()
                                                    );

                                                    return (
                                                        <tr key={`merchant-${idx}`}>
                                                            <td>
                                                                <div className="">
                                                                    <Text as="h3" fontWeight="headingMd">{item?.shopName}</Text>
                                                                </div>
                                                                <p className="mt-100">{item?.email}</p>
                                                                <p className="mt-100"><a href={`https://${item?.shopUrl}`} target="_blank">{item?.shopUrl}</a></p>
                                                                <p className="mt-100">{item?.meta.shopifyPlan}</p>
                                                                <p className="mt-100">{item?.plan.name}</p>
                                                                {
                                                                    !!itemTemp.meta.isUninstalled ? (
                                                                        <>
                                                                            <p className="mt-100">Installed at: {convertToAsiaTimeZone(item.createdAt)}</p>
                                                                            <p className="mt-100">Uninstalled at: {convertToAsiaTimeZone(item.updatedAt)}</p>
                                                                        </>
                                                                    ) : (
                                                                        <p className="mt-100">Installed at: {convertToAsiaTimeZone(item.createdAt)}</p>
                                                                    )
                                                                }
                                                                {
                                                                    itemTemp.meta.isDeletedData ? (
                                                                        <p className="mt-400">
                                                                            <Badge tone="critical" hideIcon>
                                                                                <Text alignment="justify" as="p" fontWeight="bold">IS DELETED APP DATA</Text>
                                                                            </Badge>
                                                                        </p>
                                                                    ) : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    !!unlockData?.[itemTemp.shopUrl] ?
                                                                        <>
                                                                            <div className="mb-200">
                                                                                <Badge tone="success">Unlocked</Badge>
                                                                            </div>
                                                                            <Button variant="secondary" loading={loadingUnlock?.[itemTemp.shopUrl]} onClick={() => {
                                                                                handleUnlock(itemTemp, false)
                                                                            }}>Lock it again</Button>
                                                                        </> : (
                                                                            <>
                                                                                <Button variant="primary" loading={loadingUnlock?.[itemTemp.shopUrl]} onClick={() => {
                                                                                    handleUnlock(itemTemp, true)
                                                                                }}>Unlock now</Button>
                                                                            </>
                                                                        )
                                                                }
                                                            </td>
                                                            <td>
                                                                <SyncSections shopUrl={itemTemp.shopUrl} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <Pagination
                                        total={pagination.total}
                                        limit={limit}
                                        page={page}
                                        onChange={page => { setPage(page) }}
                                    /></>
                            )
                        }
                    </div>
                </Frame>
            </Card>
        </Page>

    )
}

export default UnlockSections