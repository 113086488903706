import { Button, Frame, Icon, Text, Toast } from "@shopify/polaris";
import { DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useCallback, useEffect, useState } from "react";
import { DragHandleIcon, DeleteIcon, ArrowUpIcon } from '@shopify/polaris-icons';
import { ENDPOINT } from "../../utils/functions";
import SearchSections from "./SearchSections";

function SyncSections({ shopUrl }) {
    const [items, setItems] = useState({
        default: [],
        most_recent: [],
        popularity: [],
        demo: []
    });
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [syncType, setSyncType] = useState('default');
    const [loadingUnlock, setLoadingUnlock] = useState({});
    const [unlockData, setUnlockData] = useState({});

    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const toastMarkup = active ? (
        <Toast content={message} onDismiss={toggleActive} />
    ) : null;


    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over || active.id === over.id) return;

        setItems((currentItems) => {
            const currentItem = currentItems[syncType];
            const oldIndex = currentItem.findIndex((item) => item.id === active.id);
            const newIndex = currentItem.findIndex((item) => item.id === over.id);
            const _array = arrayMove(currentItem, oldIndex, newIndex);
            return {
                ...currentItems,
                [syncType]: _array
            }
        });
    };

    useEffect(() => {
        if (!items.length) {
            const fetchListFromMeta = async () => {
                const response = await fetch(`${ENDPOINT}/api/get-gift-sections`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        shopUrl
                    }),
                });
                const result = await response.json();
                setItems(prev => ({ default: result.data }));
            }
            fetchListFromMeta();
        }
    }, [shopUrl]);

    const handleDelete = (value) => {
        const newItems = items[syncType].filter(item => item.handle !== value);
        setItems(prev => ({ ...prev, [syncType]: newItems }));
    }

    const handleShift = (value) => {
        const _items = [...items[syncType]];
        const index = _items.findIndex(item => item.handle === value);

        if (index > -1) {
            const [item] = _items.splice(index, 1);
            _items.unshift(item);
        }

        setItems(prev => ({ ...prev, [syncType]: _items }));
    }

    const handleSave = async () => {
        setLoadingUnlock({ [shopUrl]: true });
        const response = await fetch(`${ENDPOINT}/api/gift-sections`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl,
                sections: items?.default ?? []
            }),
        });
        const result = await response.json();
        
        if (result.status) {
            setMessage('Saved gift sections!')
        } else {
            setMessage('Have an error, please try again!')
        }
        toggleActive();

        setLoadingUnlock({ [shopUrl]: false });
    }

    return (
        <>
            <div className="flex-1">
                <div className="sync-panel">
                    <div className="d-flex gap-100">
                        <div className="flex-1">
                            <SearchSections currentItems={items[syncType]} onSelectItem={(value) => {
                                const newItems = {
                                    ...items,
                                    [syncType]: [value, ...items[syncType]]
                                }
                                setItems(newItems);
                            }} />
                        </div>
                        <Button variant="primary" onClick={handleSave} loading={loadingUnlock?.[shopUrl]}>Save</Button>
                    </div>

                    <div className="sync-list mt-400">
                        <DndContext onDragEnd={handleDragEnd}>
                            <SortableContext items={items?.[syncType].map((item) => item.id)}>
                                {items?.[syncType].map((item, index) => (
                                    <SortableItem index={(index + 1)} key={item.id} id={item.id} name={item.name} onDelete={handleDelete} onShift={handleShift} />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </div>
                </div>
                <div>
                    {toastMarkup}
                </div>
            </div>
        </>
    )
}
export default SyncSections;

function SortableItem({ id, name, index, onDelete, onShift }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '10px',
        margin: '5px',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        borderRadius: '5px',
        cursor: 'grab',
        userSelect: 'none'
    };

    return (
        <>
            <div className="d-flex gap-100 justify-space-between" ref={setNodeRef} style={style} {...attributes}>
                <div className="d-flex gap-100">
                    <div className="d-flex align-items-center gap-100" {...listeners}>
                        {index}
                        <Icon source={DragHandleIcon} tone="base" />
                    </div>
                    <Text variant="bodyMd" fontWeight="medium">
                        <a target="_blank" style={{ color: '#000', textDecoration: 'none' }}>
                            {name}
                        </a>
                    </Text>
                </div>
                <div className="d-flex align-items-center gap-600">
                    {
                        index > 1 ?
                            <div className="cursor-pointer" onClick={() => onShift(id)}>
                                <Icon source={ArrowUpIcon} tone="base" />
                            </div> : null
                    }
                    <div className="cursor-pointer" onClick={() => onDelete(id)}>
                        <Icon source={DeleteIcon} tone="critical" />
                    </div>
                </div>
            </div>

        </>
    );
}