import { Banner, Button, Icon, Select, Text } from "@shopify/polaris";
import { DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useEffect, useState } from "react";
import { DragHandleIcon, DeleteIcon, ArrowUpIcon } from '@shopify/polaris-icons';
import { ENDPOINT } from "../../../utils/functions";

import SearchStyles from "./SearchStyles";

function SyncStyles({ toggle, onFetch }) {
    const [items, setItems] = useState({
        most_recent: [],
        popularity: [],
        demo: []
    });

    const [loading, setLoading] = useState(false);
    const [syncType, setSyncType] = useState('most_recent');
    const [syncMessage, setSyncMessage] = useState({
        status: false,
        message: ''
    });

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over || active.id === over.id) return;

        setItems((currentItems) => {
            const currentItem = currentItems[syncType];
            const oldIndex = currentItem.findIndex((item) => item.id === active.id);
            const newIndex = currentItem.findIndex((item) => item.id === over.id);
            const _array = arrayMove(currentItem, oldIndex, newIndex);
            return {
                ...currentItems,
                [syncType]: _array
            }
        });
    };

    const handleSync = async () => {
        try {
            setLoading(true);
            setSyncMessage({
                status: false,
                message: ''
            });
            const response = await fetch(`${ENDPOINT}/api/sync-styles`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    syncType,
                    itemsData: items[syncType]
                }),
            });
            const result = await response.json();
            setSyncMessage(result);
            setLoading(false);
        } catch (e) {
            setSyncMessage({
                status: true,
                message: 'Cập nhật thành công'
            });
            setLoading(false);
        }
    }

    const onHandleFetch = () => {
        const data = onFetch();
        const newItems = { ...items, [syncType]: data }
        setItems(newItems);
        handleFetchPreviews(newItems);
    }

    useEffect(() => {
        handleFetchPreviews();
    }, [syncType])

    useEffect(() => {
        if (!items.length) {
            const fetchListFromMeta = async () => {
                const response = await fetch(`${ENDPOINT}/api/get-sync-styles`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({}),
                });
                const result = await response.json();
                setItems(result.data);
                handleFetchPreviews(result.data);
            }
            fetchListFromMeta();
        }
    }, []);

    const handleDelete = (value) => {
        const newItems = items[syncType].filter(item => item.handle !== value);
        setItems(prev => ({ ...prev, [syncType]: newItems }));
    }

    const handleShift = (value) => {
        const _items = [...items[syncType]];
        const index = _items.findIndex(item => item.handle === value);

        if (index > -1) {
            const [item] = _items.splice(index, 1);
            _items.unshift(item);
        }

        setItems(prev => ({ ...prev, [syncType]: _items }));
    }

    const [previews, setPreviews] = useState({});
    const handleFetchPreviews = async (_items = false) => {
        let handles = [];
        if (_items) {
            handles = _items?.[syncType].map(item => item.handle);
        } else {
            handles = items?.[syncType].map(item => item.handle);
        }

        const response = await fetch(`${ENDPOINT}/api/sync-style-previews`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                handles
            }),
        });
        const result = await response.json();
        setPreviews(result?.data ?? {});
    }

    return (
        <>
            <div className="flex-1" style={{ paddingLeft: '100px' }}>
                <div className="sync-panel" style={{ display: toggle ? 'block' : 'none' }}>
                    <div className="d-flex align-items-center justify-space-between">
                        <Button size="large" onClick={onHandleFetch}>Fetch data from left panel</Button>
                        <div className="d-flex align-items-center gap-500 justify-space-between">
                            <div className="d-flex align-items-center gap-200">
                                <Text>Sync data to app</Text>
                                <Select
                                    value={syncType}
                                    onChange={value => {
                                        setSyncType(value);
                                    }}
                                    options={[
                                        {
                                            label: 'Most recent',
                                            value: 'most_recent'
                                        },
                                        {
                                            label: 'Popularity',
                                            value: 'popularity'
                                        },
                                        {
                                            label: 'Demo',
                                            value: 'demo'
                                        }
                                    ]}>

                                </Select>
                            </div>
                            <Button size="large" variant="primary" onClick={handleSync} loading={loading}>Sync now</Button>
                        </div>
                    </div>
                    {
                        syncMessage.message !== '' ? (
                            <div className="mt-300">
                                <Banner onDismiss={() => setSyncMessage({ status: false, message: '' })} tone={syncMessage.status ? 'success' : 'critical'}>{syncMessage.message}</Banner>
                            </div>
                        ) : null
                    }

                    <SearchStyles currentItems={items[syncType]} onSelectItem={(value) => {
                        const newItems = {
                            ...items,
                            [syncType]: [value, ...items[syncType]]
                        }
                        setItems(newItems);
                        handleFetchPreviews(newItems);

                    }} />

                    <div className="sync-list mt-400">
                        <DndContext onDragEnd={handleDragEnd}>
                            <SortableContext items={items?.[syncType].map((item) => item.id)}>
                                {items?.[syncType].map((item, index) => (
                                    <SortableItem index={(index + 1)} key={item.id} id={item.id} name={item.name} preview={previews?.[item.handle]} onDelete={handleDelete} onShift={handleShift} />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SyncStyles;

function SortableItem({ id, name, preview, index, onDelete, onShift }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '10px',
        margin: '5px',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        borderRadius: '5px',
        cursor: 'grab',
        userSelect: 'none'
    };

    return (
        <>
            <div className="d-flex gap-100 justify-space-between" ref={setNodeRef} style={style} {...attributes}>
                <div className="d-flex gap-100">
                    <div className="d-flex align-items-center gap-100" {...listeners}>
                        {index}
                        <Icon source={DragHandleIcon} tone="base" />
                    </div>
                    <Text variant="bodyMd" fontWeight="medium">
                        <a target="_blank" href={preview} style={{ color: '#000', textDecoration: 'none' }}>
                            {name}
                        </a>
                    </Text>
                </div>
                <div className="d-flex align-items-center gap-600">
                    {
                        index > 1 ?
                            <div className="cursor-pointer" onClick={() => onShift(id)}>
                                <Icon source={ArrowUpIcon} tone="base" />
                            </div> : null
                    }
                    <div className="cursor-pointer" onClick={() => onDelete(id)}>
                        <Icon source={DeleteIcon} tone="critical" />
                    </div>
                </div>
            </div>

        </>
    );
}