import { Spinner, LegacyCard, Page, ResourceList, Filters, Text, Button, Icon, Modal , Select, ChoiceList } from "@shopify/polaris";
import { ArrowLeftIcon } from '@shopify/polaris-icons';

import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash"
import { ENDPOINT, incrementVersion } from "../../utils/functions";
import RichtextField from "../fields/RichtextField";
import ListChangelogs from "./ListChangelogs";

function ChangeLogs() {
    const [loading, setLoading] = useState(false);
    const [queryValue, setQueryValue] = useState(null);
    const [sections, setSections] = useState(null);
    const [searchLoading, setSearchLoading] = useState(false);
    const [listItemResourceList, setListItemResourceList] = useState([])
    const [items, setItems] = useState([]);
    const optionsType = [
        {label: 'Section', value: 'section'},
        {label: 'Theme', value: 'theme'},
    ];

    const [sortValue, setSortValue] = useState('section');
 
    

    const [step, setStep] = useState(1); 
    const [itemSelected, setItemSelected] = useState([]);

    //Active modal add changelog
    const [activeModalAddChangelogs, setActiveModalAddChangelogs] = useState(false);
    
    const handleQueryChange = (value) => {
        setItemSelected([]);
        setQueryValue(value)
        delayedSearchSection(value);
    }
    const delayedSearchSection = useCallback(
        debounce(async(query) => {
            setSearchLoading(true);
            const handles = items.map(item => item.handle);                
            
            const response = await fetch(`${ENDPOINT}/api/search-item-changelog`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    handles,
                    _s: query,
                    type : sortValue
                }),
            });  
            const result = await response.json();    
            let _sections = [];
            if(result.data){
                result.data.map(item => {
                    _sections.push({
                        content: item.name,
                      });
                })
                setSections(_sections);
                setListItemResourceList(result.data);
            }
            
            setSearchLoading(false);
            }, 250),
        [queryValue , sortValue]
    );
    const handleSelectTypeChange = (value) => {
        setSortValue(value);
    }


    const handleClearAll = () => {
        setQueryValue('')
        setItemSelected([])
    }

    const handleViewChangeLogsItem = (prev,item) => {
        setItemSelected(item);
        setStep(2);
    } 
    const renderItemsSection = (item) => {
        const { core, coreName } = item
        return (
            <ResourceList.Item id={core}>
                <div className="d-flex justify-space-between align-items-center">
                    <Text as="h3" variant="bodyMd" fontWeight="bold">{coreName}</Text>
                    <Button variant="primary" onClick={(prev) => handleViewChangeLogsItem(prev, item)}>View Changelogs</Button>
                </div>
                
            </ResourceList.Item>
        )
    }
    
    const handleBackToChangelogsManager = () => {
        setStep(1);
        delayedSearchSection(queryValue);
    }

    const handleCreatehangeLog = (prev,item) => {
        setActiveModalAddChangelogs(true);
    }

    const handleChangeModalAddChangelogs = useCallback(() => setActiveModalAddChangelogs(!activeModalAddChangelogs), [activeModalAddChangelogs]);
    
    //Modal Insert data
    const [dataContent, setData] = useState();
    const handleChangeValue = (value) => {        
        setData(value);
    }
    const [dataInsert, setDataInsert] = useState({});
    useEffect(() => {
        if (dataContent) {
            console.log(dataContent,333);
            delete(itemSelected['_id'])
            delete(itemSelected['handle'])
            delete(itemSelected['id'])
            delete(itemSelected['itemId'])
            delete(itemSelected['name'])
            const dataInsertObj= {...itemSelected, changelogContent:dataContent, updateChangelogDate : new Date().getTime(), version: incrementVersion(itemSelected.version) }
            setDataInsert(dataInsertObj)
        }
    }, [dataContent]);

    const handleAddChangelog = async() => {
        const newData = {...dataInsert, type:sortValue};
        const response = await fetch(`${ENDPOINT}/api/add-changelog`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                dataInsert: newData,
                
            }),
        });  
        const result = await response.json();
        if(result.status){
            setData("");
            setActiveModalAddChangelogs(false)
            setItemSelected({
                ...itemSelected,
                version: incrementVersion(itemSelected.version)
            });
        }
        setRefreshChangeLogsList(true);
    }

    useEffect(() => {
        delayedSearchSection(queryValue);
        return () => delayedSearchSection.cancel();
    },[sortValue])
    
    const [refreshChangeLogsList, setRefreshChangeLogsList] = useState(false); 

   
    return (
        <div className="smi-settings-changelog mt-400 d-flex justify-center">
            {loading && <div className="_loading"><Spinner size="large" /></div>}
            {step == 1 && (
                <Page title="Changelogs Manager">
                    <Select
                        label="Type changelog"
                        options={optionsType}
                        onChange={handleSelectTypeChange}
                        value={sortValue}
                    />
                    <LegacyCard>
                        <ResourceList
                            resourceName={{ singular: "changelog", plural: "changelogs" }}
                            filterControl={
                                <Filters
                                    loading={searchLoading}
                                    queryPlaceholder={sortValue == 'section' ? ("Search section base") : ("Search theme base")}
                                    filters={[]}
                                    queryValue={queryValue}
                                    onQueryChange={handleQueryChange}
                                    onQueryClear={handleClearAll}
                                    onClearAll={handleClearAll}
                                />
                            }
                            flushFilters
                            items={listItemResourceList}
                            renderItem={renderItemsSection}
                        />
                    </LegacyCard>
                </Page>
            )}
    
            {step == 2 && (
                <Page 
                    fullWidth 
                    title={
                        <div className="w-100 d-flex flex-wrap align-items-center justify-space-between gap-200 cursor-pointer">
                            <span onClick={handleBackToChangelogsManager} className="d-flex flex-wrap align-items-center gap-200 cursor-pointer">
                                <Icon source={ArrowLeftIcon} tone="base" />
                                Changelogs list
                            </span>
                            
                            <Button variant="primary" onClick={(prev) => handleCreatehangeLog(prev, itemSelected)}>Create Changelog</Button>
                        </div>
                    }
                >
                    <ListChangelogs type={sortValue} itemSelected={itemSelected} refreshChangeLogsList={refreshChangeLogsList} setRefreshChangeLogsList={setRefreshChangeLogsList}/>
                    <Modal
                        open={activeModalAddChangelogs}
                        onClose={handleChangeModalAddChangelogs}
                        title={`Create changelogs for ${itemSelected.coreName}`}
                        primaryAction={{
                            content: 'Add Changelog',
                            onAction: () => { handleAddChangelog(itemSelected)},
                        }}
                        >
                        <Modal.Section>
                            <RichtextField 
                                setting={itemSelected}
                                data={dataContent}
                                className={"richtextField"}
                                onChange={handleChangeValue}
                                />
                        </Modal.Section>
                    </Modal>
                </Page>
            )}
        </div>
    );
    
}

export default ChangeLogs;
