import { IndexTable, Text, useIndexResourceState, IndexFilters , LegacyCard, useSetIndexFiltersMode, Button, Modal } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { ENDPOINT } from "../../utils/functions";
import { EditIcon } from '@shopify/polaris-icons';
import RichtextField from "../fields/RichtextField";
function ListChangelogs({ itemSelected , refreshChangeLogsList, setRefreshChangeLogsList, type }) {
    const [listChangelogs, setListChangelogs] = useState([]);
    const [queryValue, setQueryValue] = useState("");
    const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
        listChangelogs, 
        { resourceIDResolver: (changelog) => changelog._id }
    );
    const [activeModalEditChangelogs, setActiveModalEditChangelogs] = useState(false);
    const [itemSelectedVersion, setItemSelectedVersion] = useState([]);
    useEffect(() => {
        console.log(refreshChangeLogsList);
        if(refreshChangeLogsList == true){
            setRefreshChangeLogsList(false);
            delayedGetChangelogsList();
            return () => delayedGetChangelogsList.cancel();
        }
    },[refreshChangeLogsList])
    
    const { mode, setMode } = useSetIndexFiltersMode();
    const delayedGetChangelogsList = useCallback(
        debounce(async () => {
            try {
                const response = await fetch(`${ENDPOINT}/api/get-changelogs-by-core`, {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ core : itemSelected.core , type : type}),
                });

                if (!response.ok) throw new Error("Failed to fetch changelogs");

                const result = await response.json();
                setListChangelogs(result.data || []);
                

            } catch (error) {
                console.error("Error fetching changelogs:", error);
                setListChangelogs([]);
            }
        }, 250),
        [itemSelected.core, refreshChangeLogsList]
    );

    useEffect(() => {
        delayedGetChangelogsList();
        return () => delayedGetChangelogsList.cancel(); // Cancel debounce khi unmount
    }, [delayedGetChangelogsList]);

    const handleEditVersion = (prev, item) => {
        setActiveModalEditChangelogs(true);
        setItemSelectedVersion(item);
        console.log(itemSelectedVersion);
    }
    // Tạo danh sách dòng cho bảng
    const rowMarkup = listChangelogs?.map((changelog, index) => {
        return (
            <IndexTable.Row
            id={changelog._id}
            key={changelog._id}
            selected={selectedResources.includes(changelog._id)}
            position={index}
            onClick={() => { }}
            >
                <IndexTable.Cell className="table-cell-large">
                    <div className="d-inline-flex flex-column gap-200">
                        <div className="d-flex gap-400 filter-name">
                            <Text variant="bodyMd" fontWeight="bold" as="span">
                                {`${changelog.coreName} version ${changelog.version}`}
                            </Text>
                        </div>
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Button alignment="end"  icon={EditIcon} onClick={(prev) => handleEditVersion(prev, changelog)}>Edit</Button>
                </IndexTable.Cell>
            </IndexTable.Row>
        );
    });

    let promotedBulkActions = [
        {
            destructive: true,
            content: 'Delete',
            onAction: () => { handleBulkDelete(true) }
        }
    ];

    const handleBulkDelete = async (popup = false) => {
        
    }
    const handleFiltersClearAll = useCallback(() => {
    });
    
    const handleFiltersQueryChange = (value) => {
        setQueryValue(value);        
    }
    const onHandleCancel = () => {
        setQueryValue('');        
    };
    const handleChangeModalEditChangelogs = useCallback(() => setActiveModalEditChangelogs(!activeModalEditChangelogs), [activeModalEditChangelogs]);
    //Modal Insert data
    const [dataContent, setData] = useState("");
    const handleChangeValue = (value) => {        
        setData(value);
    }

    useEffect(() => {
        if(itemSelectedVersion){
            setData(itemSelectedVersion.changelogContent);
        }
    }, [itemSelectedVersion]);

    const [dataUpdate, setDataUpdate] = useState({});
    useEffect(() => {
        if (dataContent) {
            const dataInsertObj= {...itemSelectedVersion,changelogContent : dataContent }
            setDataUpdate(dataInsertObj)
        }
    }, [dataContent]);

    const handleEditChangelog = async() => {
        if(dataUpdate){
            const response = await fetch(`${ENDPOINT}/api/edit-changelog`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    dataUpdate: dataUpdate,
                    type : 'section'
                }),
            });  
            const result = await response.json();
            
            setRefreshChangeLogsList(true);
            setActiveModalEditChangelogs(false);
        }
        
    }
    return (
        <div class="listChangelogs">
            <LegacyCard>
                <IndexFilters
                    queryValue={queryValue}
                    queryPlaceholder="Searching in all"
                    onQueryChange={(value) => handleFiltersQueryChange(value)}
                    onQueryClear={() => setQueryValue('')}
                    cancelAction={{
                        onAction: onHandleCancel,
                        disabled: false,
                    }}
                    tabs={[]}
                    selected={''}
                    filters={[]}
                    appliedFilters={[]}
                    onClearAll={() => { }}
                    mode={mode}
                    setMode={setMode}
                    hideFilters
                    filteringAccessibilityTooltip="Search (F)"
                />
                <IndexTable
                    resourceName={{ singular: 'changelog', plural: 'changelogs' }}
                    itemCount={listChangelogs.length}
                    selectedItemsCount={
                        allResourcesSelected ? 'All' : selectedResources.length
                    }
                    promotedBulkActions={promotedBulkActions}
                    onSelectionChange={handleSelectionChange}
                    headings={[
                        { title: "Name Core" },
                        { title: 'Action' }
                    ]}
                    >
                    {rowMarkup}
                </IndexTable>
            </LegacyCard>
            <Modal
                open={activeModalEditChangelogs}
                onClose={handleChangeModalEditChangelogs}
                title={`Edit changelogs for ${itemSelectedVersion.coreName}`}
                primaryAction={{
                    content: 'Save',
                    onAction: () => { handleEditChangelog(itemSelectedVersion)},
                }}
                >
                <Modal.Section>
                    <RichtextField 
                        setting={itemSelectedVersion}
                        data={dataContent}
                        className={"richtextField"}
                        onChange={handleChangeValue}
                        />
                </Modal.Section>
            </Modal>
        </div>
       
    );
}

export default ListChangelogs;
