import { Banner, Button, ChoiceList, Divider, Icon, Popover, Select, Text, TextField } from "@shopify/polaris";
import { format } from "date-fns";
import _, { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { XIcon } from '@shopify/polaris-icons';
import { ENDPOINT } from "../../../utils/functions";

function TemplateInstalled({ shop, data }) {
    const [sections, setSections] = useState(data);
    const [popoverPageTypeActive, setPopoverPageTypeActive] = useState(false);
    const [pageType, setPageType] = useState([]);
    const [sortBy, setSortBy] = useState('name');
    const [filters, setFilters] = useState({
        pageType: [],
        sortBy: 'name'
    });
    const [handles, setHandles] = useState({});

    const togglePopoverPageTypeActive = () => {
        setPopoverPageTypeActive((popoverActive) => !popoverActive)
    };
    const handleChangePageType = (value) => {
        setPageType(value);
        setFilters(prev => ({ ...prev, pageType: value }));
    };

    const handleChangeSortBy = (value, _data = false) => {
        setSortBy(value);
        setFilters(prev => ({ ...prev, sortBy: value }));
    }

    useEffect(() => {
        let _sections = [...data];

        if (filters.pageType.length) {
            _sections = _sections.filter(item => filters.pageType.includes(item.pageType));
        }

        if (filters.sortBy === 'date') {
            _sections = _sections.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else {
            _sections = _sections.sort((a, b) => {
                return a.meta.templateName.localeCompare(b.meta.templateName);
            });
        }

        setSections(_sections);

    }, [filters]);

    useEffect(() => {
        const handleInit = async () => {
            const responseHandles = await fetch(`${ENDPOINT}/api/get-handles`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    shopUrls: [shop.shopUrl]
                }),
            });
            const resultHandles = await responseHandles.json();
            setHandles(resultHandles.data[shop.shopUrl]);
        }

        handleInit();
    }, [])

    return (
        <>
            <div className="mb-100 mt-400 d-flex gap-300">
                <Popover
                    active={popoverPageTypeActive}
                    activator={(
                        <Button disclosure size="large" onClick={togglePopoverPageTypeActive}>Page type</Button>
                    )}
                    onClose={togglePopoverPageTypeActive}
                    ariaHaspopup={false}
                    sectioned
                    preferredAlignment="left"
                >
                    <ChoiceList
                        allowMultiple
                        choices={[
                            {
                                label: 'Index',
                                value: 'index'
                            },
                            {
                                label: 'Collection',
                                value: 'collection'
                            },
                            {
                                label: 'Product',
                                value: 'product'
                            },
                            {
                                label: 'Page',
                                value: 'page'
                            },
                            {
                                label: 'Blog',
                                value: 'blog'
                            },
                            {
                                label: 'Article',
                                value: 'article'
                            }
                        ]}
                        selected={pageType}
                        onChange={handleChangePageType}
                    />
                </Popover>
                <Select
                    label="Sort by"
                    labelInline
                    value={sortBy}
                    onChange={handleChangeSortBy}
                    options={[
                        {
                            label: 'Name A-Z',
                            value: 'name'
                        },
                        {
                            label: 'Created date',
                            value: 'date'
                        }
                    ]}
                >

                </Select>
            </div>
            <div className="">
                {
                    pageType.length ?
                        (
                            <div className="list-tags d-flex gap-200">
                                Page type
                                {
                                    pageType.map(item => {
                                        return (
                                            <span key={item} className="tag d-flex">{item} <span onClick={() => {
                                                const remainItems = pageType.filter(it => it !== item);
                                                setPageType(remainItems);
                                                setFilters(prev => ({ ...prev, pageType: remainItems }))
                                            }}><Icon source={XIcon} tone="base"></Icon></span></span>
                                        )
                                    })
                                }
                            </div>
                        ) : null
                }
            </div>
            <div className="my-400">
                <Banner hideIcon><Text fontWeight="bold" variant="headingLg">{sections.length} {sections.length === 1 ? 'item' : 'items'}</Text></Banner>
            </div>
            <div className="d-flex align-items-center gap-300 pb-300" style={{ width: '100%' }}>
                <div style={{ width: '5%' }}><b>#</b></div>
                <div style={{ width: '40%' }}><b>Template</b></div>
                <div style={{ width: '10%' }}><b>Type</b></div>
                <div style={{ width: '20%' }}><b>Theme ID</b></div>
                <div style={{ width: '25%' }}><b>Created at</b></div>
            </div>
            {
                sections.length ? sections.map((section, idx) => {
                    const handleObj = {...handles};
                    const shopUrl = shop?.shopUrl;
                    const type = section.pageType;

                    let url = '';

                    switch (type) {
                        case 'blog':
                            url = `https://${shopUrl}/blogs/${handleObj?.blog}?view=smi-${section.meta.templateHandle}&preview_theme_id=${section.theme}`;
                            break;
                        case 'article':
                            url = `https://${shopUrl}/blogs/${handleObj?.blog}/${handleObj?.article}?view=smi-${section.meta.templateHandle}&preview_theme_id=${section.theme}`;
                            break;
                        case 'product':
                            url = `https://${shopUrl}/products/${handleObj?.product}?view=smi-${section.meta.templateHandle}&preview_theme_id=${section.theme}`;
                            break;
                        case 'collection':
                            url = `https://${shopUrl}/collections/${handleObj?.collection}?view=smi-${section.meta.templateHandle}&preview_theme_id=${section.theme}`;
                            break;
                        case 'page':
                            if (!!section.meta.createdPage) {
                                url = `https://${shopUrl}/pages/${section.meta.pageHandle}?view=smi-${section.meta.templateHandle}&preview_theme_id=${section.theme}`;
                            } else {
                                url = `https://${shopUrl}/pages/${handleObj?.page}?view=smi-${section.meta.templateHandle}&preview_theme_id=${section.theme}`;
                            }
                            break;
                        case 'index':
                            url = `https://${shopUrl}/pages/${handleObj?.page}?view=index.smi-${section.meta.templateHandle}&preview_theme_id=${section.theme}`;
                            break;
                        default:
                            url = `https://${shopUrl}?preview_theme_id=${section.theme}`;
                            break;
                    }
                    return (
                        <>
                            <Divider />
                            <div className="_list-item py-300 d-flex gap-300" key={`installed_sections_sections-${idx}`}>
                                <div style={{ width: '5%' }}><b>{(idx + 1)}</b></div>
                                <div style={{ width: '40%' }}>
                                    <a href={url} target="_blank" className="text-link text-link-dark">
                                    <Text variant="bodyMd" fontWeight="bold" as="span">{section.meta.templateName}</Text><br />
                                    {
                                        !!section.meta.createPage && !section.meta.asHome && section.pageType === 'page' ? (
                                            <>
                                                Page: <Text variant="bodyMd" fontWeight="regular" as="span">{section.meta.pageName}</Text>
                                            </>
                                        ) : null
                                    }
                                    </a>
                                </div>
                                <div style={{ width: '10%' }}>
                                    {_.capitalize(section.pageType)}
                                </div>
                                <div style={{ width: '20%' }}>
                                    <a href={url} target="_blank" className="text-link">
                                        <Text variant="bodyMd" fontWeight="regular" as="span">{section.theme}</Text>
                                    </a>
                                </div>
                                <div style={{ width: '25%' }}>
                                    <Text variant="bodyMd" fontWeight="regular" as="span">{format(section.createdAt, "dd-MM-yyyy HH:mm:ss", { timeZone: 'Asia/Bangkok' })}</Text>
                                </div>
                            </div>
                        </>
                    )
                }) : <div className="py-300"><Banner>No data</Banner></div>
            }
        </>
    )
}

export default TemplateInstalled;