//const ENDPOINT = 'https://tools-api.smind.io'

console.log(process.env);
const ENDPOINT = process.env.REACT_APP_ENDPOINT

const convertToAsiaTimeZone = (_date) => {
    const date = new Date(_date);

    // Convert to local time with UTC+7 timezone offset
    const localDateString = date.toLocaleString('vi-VN', {
        timeZone: 'Asia/Bangkok', // UTC+7 timezone
        hour12: false, // Use 24-hour format
    });

    const temp = localDateString.split(' ')
    return `${temp[1]} ${temp[0]}`
}

const incrementVersion = (version) => {
    let parts = version.toString().split('.').map(Number);
    while (parts.length < 3) {
        parts.push(0);
    }
    parts[2]++;
    if (parts[2] === 10) {
        parts[2] = 0;
        parts[1]++;
    }
    if (parts[1] === 10) {
        parts[1] = 0;
        parts[0]++;
    }

    return parts.join('.');
}

export {
    ENDPOINT,
    convertToAsiaTimeZone,
    incrementVersion
}